<div class="error-info-container d-flex flex-column h-100 align-items-center justify-content-center"
  *ngIf="isOnline; else offline">
  <div class="error-info-box d-flex flex-column p-3">
    <div class="spinner-container" *ngIf="showSpinner">
      <div class="spinner"><img src="assets/images/Spinner.gif" /></div>
    </div>
    <span class="header-text">{{ 'resources.somethingWentWrong' | translate }}</span>
    <i class="eva eva-alert-triangle-outline mt-3 align-self-center"></i>
    <div class="small-text mt-2">{{description}}</div>
    <span class="small-text mt-1" *ngIf="userMessages.length === 1; else bullets">{{userMessages[0]}}</span>
    <ng-template #bullets>
      <ul class="mt-1">
        <li *ngFor="let message of userMessages">{{message}}</li>
      </ul>
    </ng-template>
    <div class="link align-self-end primary-color" *ngIf="showBackButton" (click)="onClickGoBack()">Go back</div>
  </div>
</div>
<ng-template #offline>
  <core-offline-screen></core-offline-screen>
</ng-template>
<nb-layout>
  <nb-layout-header fixed>
    <core-logo></core-logo>
    <span class="env-info" *ngIf="environmentName === 'dev'">{{environmentName | uppercase}}</span>
  </nb-layout-header>

  <nb-layout-column>
    <nb-card class="h-100">
      <core-something-went-wrong-screen [attr.class]="'h-100'" [userMessages]="messages" [description]="description"
        [showBackButton]="isStatusZeroError">
      </core-something-went-wrong-screen>
      <span class=" primary-color mr-auto ml-auto mt-2">{{'Brought to you by Decision Management
                Solutions'}}</span>
    </nb-card>
  </nb-layout-column>
</nb-layout>

import { Location } from '@angular/common';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ExternalService } from 'angular4-hal';
import { IGNORE_AUTHENTICATION_TOKEN_HEADER_KEY, IGNORE_AUTHENTICATION_TOKEN_HEADER_VALUE } from 'core/root-store';
import { ErrorHandlingService, APP_CONFIG } from 'core/services';
import { Subscription } from 'rxjs';

@Component({
  selector: 'core-request-failed-message',
  templateUrl: './request-failed-message.component.html',
  styleUrls: ['./request-failed-message.component.scss']
})
export class RequestFailedMessageComponent implements OnInit, OnDestroy {
  messages: string[] = ["We will get you back"];
  description: string = "";
  environmentName: string;
  isServerDown: boolean = false;
  isStatusZeroError: boolean = false;
  rootUri: any;
  private http: HttpClient;
  private subscriptions: Subscription = new Subscription();

  constructor(private external: ExternalService, private _location: Location,
    private errorHandlingService: ErrorHandlingService, private router: Router, private injector: Injector, httpBackend: HttpBackend) {
    this.http = new HttpClient(httpBackend);
    this.rootUri = injector.get(APP_CONFIG).rootUri;

    const errorState = this.errorHandlingService.getErrorState();
    if (errorState.isServerDown) {
      this.setServerDownError();
    } else if (errorState.isStatusZeroError) {
      this.setStatusZeroError();
    }
  }

  ngOnInit() {
    this.environmentName = this.external.getExternalConfiguration()['environmentName'];
    this.subscriptions.add(this.errorHandlingService.serverStateCheckTimeout$.subscribe(errorState => {
      if (errorState.isServerDown === true) {
        this.setServerDownError();
      } else {
        this.http.get(`${this.external.getURL()}dfmInformations`, {
          headers: { [IGNORE_AUTHENTICATION_TOKEN_HEADER_KEY]: IGNORE_AUTHENTICATION_TOKEN_HEADER_VALUE }
        }).subscribe((res: any) => {
          if (res.status === 0) {
            this.setStatusZeroError();
          } else if (res.status === 200) {
            this.errorHandlingService.resetErrorState();
            this.router.navigateByUrl('/decision-first');
          }
        });
      }
    }));
  }

  setServerDownError() {
    this.isServerDown = true;
    this.messages = ['Server is unreachable'];
  }

  setStatusZeroError() {
    this.isStatusZeroError = true;
    this.messages = ['Refresh the page', 'Please check your internet connection', 'Contact your network admin (for firewall blocking or filtering)'];
    this.description = "Try the following:";
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}

import { EnvName, IdentityManagement } from './config.model';
import { IConfigMap } from './configMap';

export const devConfig: IConfigMap = {
  environment: EnvName.DEV,
  name: EnvName.DEV,
  proxyUri: '',
  rootUri: 'https://beta.api.decisionsfirst.com/',
  authBaseUrl: "https://beta.api.decisionsfirst.com/authentication/",
  identityManagement: IdentityManagement.INAPP,
};

import { Component, Input, OnInit } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { IPagination } from "core/models";
import { Observable, combineLatest } from "rxjs";

@Component({
  selector: "core-objects-list",
  template: "",
})
export class ObjectsListComponent implements OnInit {
  @Input() set objectsList(value: any) {
    this._objectsList = value;
    if (this.loadPermissionListAction != undefined) {
      this.objectIds = [];
      this.objectId = "";
      for (let object of this._objectsList) {
        if (object) {
          this.objectIds.push(object.id);
        }
      }
      this.objectId = this.objectIds.toString();
      this.loadPermissionList(this.objectId);
      this.getStateForEvent();
    }
  }
  get objectsList(): any {
    return this._objectsList;
  }
  _objectsList: any;
  @Input() set userType(value: string) {
    this._userType = value;
  };
  get userType(): string {
    return this._userType;
  }
  _userType: string;
  @Input() isReadOnly: boolean;
  objectIds: String[];
  objectId: string;
  openTabAction: any;
  deleteObjectAction: any;
  loadPermissionListAction: any;
  paginationSelector: any;
  permissionListSelector: any;
  pagination$: Observable<IPagination>;
  state$: any;
  constructor(private store: Store<unknown>) { }

  ngOnInit() {
    this.pagination$ = this.store.pipe(select(this.paginationSelector));
  }
  getStateForEvent() {
    this.state$ = this.store.pipe(select(this.permissionListSelector));
  }

  trackByFn(index: number, object: any): string {
    return object.id;
  }

  openObjectTab(object: any): void {
    this.store.dispatch(
      new this.openTabAction({
        id: object.id,
        type: object.className,
      })
    );
  }

  deleteObject(object: any): void {
    this.store.dispatch(new this.deleteObjectAction(object));
  }

  loadPermissionList(objectId: string) {
    if (objectId)
      this.store.dispatch(new this.loadPermissionListAction(objectId));
  }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'core-session-timeout',
  templateUrl: './session-timeout.component.html',
  styleUrls: ['./session-timeout.component.scss']
})
export class SessionTimeoutComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AuthenticatedUser } from 'core/models';
import { rootReducers, rootSelectors, IGNORE_AUTHENTICATION_TOKEN_HEADER_KEY } from 'core/root-store';
import { Observable } from 'rxjs';
import { first, switchMap, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ErrorHandlingService, MessageService } from 'core/services';

@Injectable()
export class TokenInterceptorService implements HttpInterceptor {
  private accessToken: string;
  private authenticatedUser$: Observable<AuthenticatedUser>;

  constructor(private store: Store<rootReducers.IState>, private router: Router,
    private errorHandlingService: ErrorHandlingService) {
    this.store
      .pipe(select(rootSelectors.getEncodedToken))
      .subscribe((accessToken) => (this.accessToken = accessToken));

    this.authenticatedUser$ = this.store
      .pipe(select(rootSelectors.getAuthenticatedUser));
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.isExternalRequest(request) && this.requestRequiresAuthentication(request)) {
      return this.authenticatedUser$.pipe(
        first(current => current && current.isAuthenticated),
        switchMap(current => next.handle(this.extendRequestWithUserInfo(request, current))
          .pipe((tap(() => { },
            (err: any) => {
              if (err instanceof HttpErrorResponse) {
                if (err.status === 0) {
                  this.handleStatusZeroEror(err);
                } else if (err.status !== 401) {
                  return;
                } else {
                  this.router.navigateByUrl('/sessionTimeout');
                }
              }
            }))
          )
        ));
    } else {
      return next.handle(request).pipe(tap(() => { },
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 0) {
              this.handleStatusZeroEror(err);
            } else if (err.status !== 401) {
              return;
            } else {
              this.router.navigateByUrl('/sessionTimeout');
            }
          }
        }));
    }
  }

  private extendRequestWithUserInfo(request: HttpRequest<any>, userInfo: AuthenticatedUser): HttpRequest<any> {
    return request.clone({
      setHeaders: {
        authToken: `${this.accessToken}`,
        userId: userInfo.userId,
        accountId: userInfo.accountId
      },
    });
  }

  private isExternalRequest(request: HttpRequest<any>): boolean {
    return request.url.startsWith('http');
  }

  private requestRequiresAuthentication(request: HttpRequest<any>): boolean {
    return !request.headers.has(IGNORE_AUTHENTICATION_TOKEN_HEADER_KEY);
  }

  private handleStatusZeroEror(error: HttpErrorResponse) {
    if (navigator.onLine === false) return;
    this.errorHandlingService.startTimer();
  }
}

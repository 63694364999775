
export enum EnvName {
    LOCAL = 'local',
    DEV = 'dev',
    DEMO = 'demo',
    STAGING = 'staging',
    PROD = 'prod',
    QA = 'qa',
    QAGREEN = 'qaGreen',
    TEMPDEV = 'temp-dev',
}

export enum IdentityManagement {
    AD = 'AD',
    WSO2 = 'WSO2',
    INAPP = 'INAPP',
}


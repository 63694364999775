import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NbDialogRef } from '@nebular/theme';
import { ActionsSubject, Store } from '@ngrx/store';
import { AddObjectComponent, MaxTextLengthCategory } from 'core/components';
import { domainsValidator } from 'core/utilities';
import { IDecisionFirstState } from 'user-management/store/reducers';
import { DEFAULT_COUNT_SHIFT_DAYS, DEFAULT_NUMBER_OF_USERS } from './constants';
import * as fromCustomerStore from 'user-management/store/actions';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'user-management-add-customer',
  templateUrl: './add-customer.component.html',
  styleUrls: ['./add-customer.component.scss']
})
export class AddCustomerComponent extends AddObjectComponent {
  MaxTextLengthCategory = MaxTextLengthCategory;

  @Input() addAction: any;

  constructor(
    private userManagementStore: Store<IDecisionFirstState>,
    private nbDialogRef: NbDialogRef<AddCustomerComponent>,
    private actions$: ActionsSubject,
  ) {
    super(userManagementStore, nbDialogRef, actions$);

    this.formGroup = this.getCustomerForm();
    this.addAction = this.addAction;
    this.addActionSuccess = fromCustomerStore.ADD_CUSTOMER_SUCCESS;
    this.addActionFailure = fromCustomerStore.ADD_CUSTOMER_FAILURE;
  }

  getDefaultValidDate(): string {
    const validDate = new Date();
    validDate.setDate(validDate.getDate() + DEFAULT_COUNT_SHIFT_DAYS);
    // validDate.setMonth(validDate.getMonth() + DEFAULT_COUNT_SHIFT_MONTHS);
    return validDate.toISOString().split('T')[0];
  }

  getCustomerForm(): FormGroup {
    return new FormGroup({
      name: new FormControl('', [Validators.required]),
      numberOfUsers: new FormControl(DEFAULT_NUMBER_OF_USERS, [Validators.required]),
      validDate: new FormControl(this.getDefaultValidDate(), [Validators.required]),
      domains: new FormControl('', [Validators.required, domainsValidator]),
    });
  }
  
  onAddCustomer(): void {
    let value: any = this.formGroup.value;
    if (value.validDate) value.validDate = new Date(value.validDate).toISOString();
    this.addObject(value);
  }
}

import { Action } from '@ngrx/store';

export * from './authentication/authentication.actions';
export * from './version-information/version-information.actions';
export * from './interfaces';
export * from './db-table/db-table.action';
export * from './common/common.actions';

export class NoOpAction implements Action {
  readonly type = 'NO OP';
}

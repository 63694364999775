import { Injectable } from "@angular/core";
import { Guid } from "guid-typescript";
import { INameReference, ITableSyncStatus, db } from "core/config";
import { MessageService } from "../message-service/message.service";
import { getFormattedName } from "core/utilities";


@Injectable()
export class IndexedDBService {

    constructor(private messageService: MessageService) { }

    async createMinimalNameReference(formattedName: string, type: string, typeResourceName: string, projectId?: string, entityId?: string, customerId?: string, showWarning: boolean = true, fetchTableVersion: boolean = true, actualName?: string): Promise<{ updatedName: string, nameReference }> {
        let tableInfo;
        if (fetchTableVersion) tableInfo = await this.getTableVersion(customerId, DBTableNames.NameReference);
        const nameReference: Partial<INameReference> = { id: Guid.create().toString(), count: 0, type, name: formattedName.trim().toLowerCase(), ...(tableInfo && { version: tableInfo.version }) };
        const objNameResult = await this.getNameReferenceWithMaxCount(nameReference.type, nameReference.name, projectId, entityId);
        if (objNameResult) {
            nameReference.count = objNameResult.count + 1;
            nameReference.name = objNameResult.name;
        }
        const showNameChangeWarning = !!(showWarning || actualName);
        if (!actualName) actualName = formattedName;
        const updatedName = this.checkForNameChange(nameReference.count, actualName, formattedName, typeResourceName, showNameChangeWarning);
        if (projectId) {
            nameReference.projectId = projectId;
            nameReference.entityId = null;
        } else {
            nameReference.projectId = null;
            nameReference.entityId = entityId;
        }
        return { updatedName, nameReference };
    }

    async getNameReferenceWithMaxCount(type: string, nameReferenceName: string, projectId?: string, entityId?: string): Promise<INameReference> {
        if (!projectId && !entityId) return;
        nameReferenceName = nameReferenceName.toLowerCase();
        let result = await db.nameReference.where({ ...(projectId ? { projectId } : { entityId }), type, name: nameReferenceName }).reverse().sortBy('count');
        if (result?.length > 0) {
            // Check count 0 exists or return null.
            if (result[result.length - 1].count > 0) {
                return null;
            }
            return result[0];
        } else {
            return null;
        }
    }

    async setToNameReferenceTable(items: INameReference[], replace: boolean = false) {
        if (replace) await db.nameReference.clear();
        return db.nameReference.bulkPut(items);
    }

    async clearTableEntries(tableName: string) {
        return db[tableName].clear();
    }

    async checkForNameReferenceUpdateByProjectId(name: string, type: string, referenceId: string, typeResourceName: string, customerId: string, projectId: string): Promise<{ updatedName: string, nameReference: Partial<INameReference>, isNew: boolean }> {
        const formattedName = getFormattedName(name);
        let nameReference = await this.findNameReferenceBy(formattedName, referenceId, projectId);
        const tableInfo = await this.getTableVersion(customerId, DBTableNames.NameReference);
        if (nameReference) {
            // Check count 0 exists in table
            let objectNameResult = await this.getNameReferenceWithMaxCount(type, nameReference.name, projectId);
            if (!objectNameResult) nameReference = { name: nameReference.name, count: 0 } as any;
            else if (objectNameResult.id != nameReference.id) nameReference.count = objectNameResult.count + 1;
            const updatedName = this.checkForNameChange(nameReference.count, name, formattedName, typeResourceName, true);
            return { updatedName, nameReference: { count: nameReference.count, name: nameReference.name, version: tableInfo.version, type }, isNew: false };
        } else {
            const { updatedName, nameReference } = await this.createMinimalNameReference(formattedName, type, typeResourceName, projectId, undefined, undefined, false, false, name);
            return { updatedName, nameReference: { count: nameReference.count, name: nameReference.name, version: tableInfo.version, type, projectId }, isNew: true };
        }
    }

    async checkForNameReferenceUpdateByEntityId(name: string, type: string, referenceId: string, typeResourceName: string, customerId: string, entityId: string): Promise<{ updatedName: string, nameReference: Partial<INameReference>, isNew: boolean }> {
        const formattedName = getFormattedName(name);
        let nameReference = await db.nameReference.where({ name: formattedName.toLowerCase(), referenceId, entityId }).first();
        const tableInfo = await this.getTableVersion(customerId, DBTableNames.NameReference);
        if (nameReference) {
            // Check count 0 exists in table
            let objectNameResult = await this.getNameReferenceWithMaxCount(type, nameReference.name, undefined, entityId);
            if (!objectNameResult) nameReference = { name: nameReference.name, count: 0 } as any;
            else if (objectNameResult.id != nameReference.id) nameReference.count = objectNameResult.count + 1;

            const updatedName = this.checkForNameChange(nameReference.count, name, formattedName, typeResourceName, true);
            return { updatedName, nameReference: { count: nameReference.count, name: nameReference.name, version: tableInfo.version, type }, isNew: false };
        } else {
            const { updatedName, nameReference } = await this.createMinimalNameReference(formattedName, type, typeResourceName, undefined, entityId, undefined, false, false, name);
            return { updatedName, nameReference: { count: nameReference.count, name: nameReference.name, version: tableInfo.version, type, entityId }, isNew: true };
        }
    }

    async getTableVersion(customerId: string, tableName: string): Promise<ITableSyncStatus> {
        return db.tableSyncStatus.where({ tableName, customerId }).first();
    }

    async setTableVersion(tableSyncState: ITableSyncStatus) {
        return db.tableSyncStatus.put(tableSyncState);
    }

    async upsertNameReference(nameReference: INameReference) {
        return db.nameReference.put(nameReference);
    }

    async increaseTableVersion(customerId: string, tableName: string) {
        const result = await this.getTableVersion(customerId, tableName);
        result.version = result.version + TABLE_VERSION_INCREMENT_NUMBER;
        return this.setTableVersion(result);
    }

    async removeNameReferenceByIds(ids: string[]) {
        return db.nameReference.bulkDelete(ids);
    }

    async getTableEntryCount(tableName: string) {
        return db[tableName].count();
    }

    async findNameReferenceBy(formattedName: string, referenceId: string, projectId: string): Promise<INameReference> {
        return db.nameReference.where({ name: formattedName.toLowerCase(), referenceId, projectId }).first();
    }

    private checkForNameChange(count: number, actualName: string, formattedName: string, typeResourceName: string, showWarning: boolean): string {
        const updatedName = `${formattedName}` + (count > 0 ? ` (${count})` : "");
        if (showWarning && (updatedName != actualName)) {
            const messageParts = ['resources.nameChangedTo', ` '${updatedName}' `];
            if (count > 0) messageParts.push('resources.toAvoidDuplication');
            this.messageService.showWarning(messageParts, typeResourceName, false);
        }
        return updatedName;
    }
}

export const TABLE_VERSION_INCREMENT_NUMBER = 0.1;

export enum DBTableNames {
    NameReference = 'nameReference',
    TableSyncStatus = 'tableSyncStatus',
}
import { OnInit, ChangeDetectorRef, Component, Input, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ExternalService } from 'angular4-hal';
// import { INTERNET_CONNECTIVITY_TEST_URL } from 'core/constants';
import { ErrorHandlingService, SERVER_STATE_CHECK_DELAY } from 'core/services';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Subscription, timer } from 'rxjs';

@Component({
  selector: 'core-something-went-wrong-screen',
  templateUrl: './something-went-wrong-screen.component.html',
  styleUrls: ['./something-went-wrong-screen.component.scss']
})
export class SomethingWentWrongScreenComponent implements OnInit, OnDestroy {
  @Input() userMessages: string[] = [];
  @Input() description: string = "";
  @Input() showBackButton: boolean = false;
  showSpinner: boolean = false;
  isOnline: boolean = true;
  internetCheckSubscription: Subscription = new Subscription();

  constructor(
    private router: Router,
    private errorHandlingService: ErrorHandlingService,
    private changeDetector: ChangeDetectorRef,
    private external: ExternalService
  ) { }

  ngOnInit() {
    this.isOnline = this.errorHandlingService.isOnline;
    if (this.isOnline === false) this.setNoInternetContent();
    this.errorHandlingService.errorState$.pipe(untilDestroyed(this))
      .subscribe(errorState => {
        this.isOnline = this.errorHandlingService.isOnline;
        if (this.isOnline === false) this.setNoInternetContent();
      });
  }

  onClickGoBack() {
    this.errorHandlingService.setStatusZeroError(false);
    this.router.navigateByUrl('/decision-first');
    this.showSpinner = true;
  }

  setNoInternetContent() {
    this.changeDetector.detectChanges();
    this.internetCheckSubscription = timer(SERVER_STATE_CHECK_DELAY * 2).subscribe(_ => {
      fetch(this.external.getExternalConfiguration()['internetConnectivityTestUrl']).then(res => {
        if (res.status === 200) {
          this.internetCheckSubscription.unsubscribe();
          this.errorHandlingService.setOnlineState(true);
        }
      });
    });
  }

  ngOnDestroy(): void {
    this.internetCheckSubscription.unsubscribe();
  }
}
import { Injectable } from '@angular/core';
import { ofType, Actions, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, mergeMap, switchMap } from 'rxjs/operators';
import * as fromActions from '../../actions';
import { VersionInformationService } from '../../services/version-information/version-information.service';

@Injectable()
export class VersionInformationEffects {
  constructor(
    private actions$: Actions,
    private versionInformationService: VersionInformationService,
  ) { }

  loadVersionInformation$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.LOAD_VERSION_INFORMATION),
    mergeMap(() => {
      return this.versionInformationService.loadVersionInformation().pipe(
        switchMap((versionInformation) => [
          new fromActions.LoadVersionInformationSuccess(versionInformation),
          // new fromActions.LoadADCode()
        ]),
        catchError((error) => of(new fromActions.VersionInformationFailure(error))),
      );
    })
  ));

  updateVersionInformation$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.UPDATE_VERSION_INFORMATION),
    mergeMap((action: fromActions.UpdateVersionInformation) => {
      return this.versionInformationService.updateVersionInformation(action.payload).pipe(
        switchMap(() => {
          return [
            new fromActions.NoOpAction(),
          ];
        }),
        catchError((error) => of(new fromActions.VersionInformationFailure(error))),
      );
    })
  ));

  // @Effect()
  // loadADCode$ = this.actions$.pipe(
  //   ofType(fromActions.LOAD_AD_CODE),
  //   map(() => this.versionInformationService.loadADCode()),
  // );
}

import { Injectable, Injector, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { StompConfig, StompRService } from '@stomp/ng2-stompjs';
import { ExternalService } from 'angular4-hal';
import { stompBaseConfig } from 'core/config';
import { rootReducers, rootSelectors } from 'core/root-store';
import { APP_CONFIG, ErrorHandlingService, SERVER_STATE_CHECK_DELAY } from 'core/services';
import { Subscription } from 'rxjs';

@Injectable()
export class AuthStompService extends StompRService implements OnDestroy {
  subscriptions: Subscription[] = [];
  rootUri: string;
  environment: string;
  config: StompConfig;
  connectionCheckInterval: any = null;

  constructor(injector: Injector, private store: Store<rootReducers.IState>, private external: ExternalService,
    private router: Router, private errorHandlingService: ErrorHandlingService) {
    super();
    this.rootUri = injector.get(APP_CONFIG).rootUri;
    this.environment = injector.get(APP_CONFIG).environment;
    this.initSocketConnection = this.initSocketConnection.bind(this);
    this.subscriptions.push(
      this.store.pipe(select(rootSelectors.getEncodedToken)).subscribe(this.initSocketConnection),
    );
  }

  checkForConnectionState() {
    if (this.connectionCheckInterval) clearInterval(this.connectionCheckInterval);
    this.connectionCheckInterval = setInterval(() => this.errorHandlingService.setServerDownState(!this.connected()),
      SERVER_STATE_CHECK_DELAY);
  }

  initSocketConnection(encodedToken) {
    if (encodedToken.length) {
      this.config = stompBaseConfig(`${this.rootUri}dfm?access_token=${encodedToken}`, this.environment);
      this.initAndConnect();
      this.checkForConnectionState();
    } else if (this.connected()) {
      this.disconnect();
    }
  }

  ngOnDestroy() {
    clearInterval(this.connectionCheckInterval);
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
export function getFormattedName(originalName: string): string {
    let name = "";
    let firstIndex = originalName.lastIndexOf('(');
    let lastIndex = originalName.lastIndexOf(')');
    if (firstIndex > -1 && lastIndex > firstIndex) {
        let subStr = originalName.substring(firstIndex, lastIndex + 1);
        if (/[0-9()]+/.test(subStr)) {
            name = originalName.replace(subStr, "");
        }
    }
    if (name.length == 0) name = originalName;
    return name.trim();
}
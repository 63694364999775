<div *ngIf="!displayForm">
  <button class="open-form-button form-control btn-info mb-2" (click)="onOpenChangePasswordForm()">{{ 'resources.changePassword' | translate }}</button>
</div>
<div class="change-password-form col-8" *ngIf="displayForm">
  <div class="validation-errors-continer mb-1">
    <nb-alert status="danger" *ngIf="validationErrorMessages.length || doDisplayIncorrectPasswordError()">
      <div *ngIf="doDisplayIncorrectPasswordError()">{{ 'resources.incorrectPassword' | translate }}</div>
      <div *ngFor="let errorMessage of validationErrorMessages">{{ errorMessage | translate }}</div>
    </nb-alert>
  </div>
  <form [formGroup]="form">
    <div class="form-group">
      <input type="password" class="form-control" placeholder="{{ 'resources.currentPassword' | translate }}" formControlName="oldPassword" autocomplete="new-password">
    </div>
    <div class="form-group">
      <input type="password" class="form-control" placeholder="{{ 'resources.newPassword' | translate }}" formControlName="newPassword" autocomplete="new-password">
    </div>
    <div class="form-group">
      <input type="password" class="form-control" placeholder="{{ 'resources.repeatNewPassword' | translate }}" formControlName="confirmPassword" autocomplete="new-password">
    </div>
  </form>
  <div class="buttons-container">
    <button class="change-password-button form-control btn-info mr-2" type="button" (click)="onChangePassword()">
      {{ 'resources.changePassword' | translate }}
    </button>
    <button class="close-form-button form-control btn-info mr-2" type="button" (click)="onCloseChangePasswordForm()">
      {{ 'resources.cancel' | translate }}
    </button>
  </div>
</div>

export enum ObjectTabType {
  Diagram = "Diagram",
  Decision = "Decision",
  InputData = "InputData",
  KnowledgeSource = "KnowledgeSource",
  Organization = "Organization",
  BusinessObjective = "BusinessObjective",
  Process = "Process",
  Event = "Event",
  System = "System",
  ImplementationComponent = "ImplementationComponent",
  Tag = "Tag",
  Account = "Account",
  User = "User",
  Group = "Group",
  ImplementationComponentIcon = "ImplementationComponentIcon",
  DecisionTable = "DecisionTable",
  ViewRequirementsNetwork = "ViewRequirementsNetwork",
  DecisionServiceDiagram = "DecisionServiceDiagram",
  Attribute = "Attribute",
}

export enum TechnicalTabType {
  Home = "Home",
  Admin = "Admin",
  Search = "Search",
  Print = "Print",
  ImplementationComponentIcons = "ImplementationComponentIcons",
  VersionInformation = "VersionInformation",
  AllTags = "AllTags",
  TagExplorer = "TagExplorer",
  AccountManager = "AccountManager",
  BranchExplorer = "BranchExplorer",
  ApproveMerge = "ApproveMerge",
  BridgeConfiguration = "BridgeConfiguration",
  PullRequest = "PullRequest",
  ArchiveManagement = "ArchiveManagement",
  Entity = "Entity",
  Testing = "Testing",
}

export enum ObjectType {
  Decision = "Decision",
  InputData = "InputData",
  KnowledgeSource = "KnowledgeSource",
}

export interface ITab {
  id?: string;
  type: ObjectTabType | TechnicalTabType;
  jumpMenuSelectedItem?: JumpMenuItems;
  objectType?: ObjectType;
  jumpTabSelectedItem?: JumpMenuItems;
  isDirty?: boolean;
  tag?: any
  metadata?: any;
  project?: any;
  branch?: any;
}

export class IStateTabs {
  tabs: ITab[];
  activeTabId: string;
}

export enum JumpMenuItems {
  BasicDetails = "BasicDetails",
  EntityDefinition = "EntityDefinition",
  Requirements = "Dependencies",
  QuestionAndAnswers = "QuestionAndAnswers",
  Implementation = "Implementation",
  ApplicationContext = "ApplicationContext",
  OrganizationContext = "OrganizationContext",
  OrganizationStructure = "OrganizationStructure",
  DecisionsInvolved = "DecisionsInvolved",
  ObjectivesInvolved = "ObjectivesInvolved",
  Owns = "Owns",
  Decisions = "Decisions",
  BusinessImpact = "BusinessImpact",
  Comments = "Comments",
  DataTypes = "DataTypes"
}

export const DefaultJumpMenuSelectedItem = JumpMenuItems.BasicDetails;

export const PRINT_TAB_ID_TYPE_SEPARATOR = "_";

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ExternalService } from 'angular4-hal';
import { VersionInformation } from 'core/models';
import { toVersionInformation } from 'core/utilities';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IGNORE_AUTHENTICATION_TOKEN_HEADER_KEY, IGNORE_AUTHENTICATION_TOKEN_HEADER_VALUE } from '../auth/auth.constants';

@Injectable()
export class VersionInformationService {
  private http: HttpClient;

  constructor(
    private externalService: ExternalService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.http = externalService.getHttp();
  }

  loadVersionInformation(): Observable<VersionInformation> {
    return this.http
      .get(`${this.externalService.getURL()}dfmInformations`,
        {
          headers: {
            [IGNORE_AUTHENTICATION_TOKEN_HEADER_KEY]: IGNORE_AUTHENTICATION_TOKEN_HEADER_VALUE
          }
        })
      .pipe(map((object: Object) => toVersionInformation(object)));
  }

  updateVersionInformation(versionInformation: VersionInformation): Observable<Object> {
    return this.http
      .patch(`${this.externalService.getURL()}dfmInformations`, versionInformation);
  }

  // loadADCode(): Observable<Object> {
  //   window.location.href = 'https://dms-ad.decisionsfirst.com/adfs/oauth2/authorize?response_type=code&client_id=9b668ef4-6011-418e-9d48-81aa948af677&redirect_uri=https://dev.modeler2.decisionsfirst.com/login';

  //   setTimeout(() => {
  //     this.activatedRoute.queryParams.subscribe(params => {
  //       const code = params['code'];
  //       console.log(code);
  //     });
  //   })
  // }

  loadNameReferenceTable() {
    return this.http.get(`${this.externalService.getURL()}nameReference`)
      .pipe(map((response: any) => (response?._embedded?.nameReferences || [])));
  }
}

import { Action } from '@ngrx/store';
import { VersionInformation } from 'core/models';

export const LOAD_NAMEREFERENCE_TABLE = '[CORE] Load Name Reference Table';
export const LOAD_NAMEREFERENCE_TABLE_SUCCESS = '[CORE] Load Name Reference Table Success';
export const LOAD_NAMEREFERENCE_TABLE_FAILED = '[CORE] Load Name Reference Table Failed';

export class LoadNameReferenceTable implements Action {
    readonly type = LOAD_NAMEREFERENCE_TABLE;
}

export class LoadNameReferenceTableSuccess implements Action {
    readonly type = LOAD_NAMEREFERENCE_TABLE_SUCCESS;
    constructor(public payload: { response: any }) { }
}

export class LoadNameReferenceTableFailed implements Action {
    readonly type = LOAD_NAMEREFERENCE_TABLE_FAILED;
    constructor(public payload: { error: any }) { }
}

export type DbTableActions = LoadNameReferenceTable
    | LoadNameReferenceTableSuccess
    | LoadNameReferenceTableFailed;

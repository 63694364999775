import { EnvName, IdentityManagement } from "./config.model";
import { IConfigMap } from "./configMap";

export const qaConfig: IConfigMap = {
    environment: EnvName.QA,
    name: EnvName.QA,
    proxyUri: "",
    rootUri: "https://qa.api.decisionsfirst.com/",
    authBaseUrl: "https://qa.api.decisionsfirst.com/ims/",
    identityManagement: IdentityManagement.INAPP,
};
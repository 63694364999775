import { EnvName, IdentityManagement } from './config.model';
import { IConfigMap } from './configMap';

export const localConfig: IConfigMap = {
  environment: EnvName.LOCAL,
  name: EnvName.LOCAL,
  proxyUri: '',
  rootUri: 'https://dfm-dms.api.apps.oc-prod.decisionsfirst.com/',
  authBaseUrl: 'https://dfm-dms.api.apps.oc-prod.decisionsfirst.com/ims/',
  identityManagement: IdentityManagement.INAPP,
};

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'core-json-compare',
  templateUrl: './json-compare.component.html',
  styleUrls: ['./json-compare.component.scss']
})
export class JsonCompareComponent implements OnInit {
  @Input() lhs: any;
  @Input() rhs: any;
  @Input() lhsHeading: string = "Original JSON";
  @Input() rhsHeading: string = "Updated JSON";
  lhsDiff: string;
  rhsDiff: string;
  private _delta: any;

  ngOnInit(): void {
    if (this._isJsonObject(this.lhs) && this._isJsonObject(this.rhs)) {
      this._compareJsons();
    }
  }

  private _compareJsons(): void {
    
  }

  private _isJsonObject(value: any): boolean {
    return typeof value === 'object' && value !== null && !Array.isArray(value);
  }
}

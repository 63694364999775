import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'core-offline-screen',
  templateUrl: './offline-screen.component.html',
  styleUrls: ['./offline-screen.component.scss']
})
export class OfflineScreenComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

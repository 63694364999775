export const SymbolsCodeRange = {
  ALPHABET_START: 65,
  ALPHABET_END: 90,
  NUMBERS_START: 48,
  NUMBERS_END: 57,
  NUMPAD_NUMBERS_START: 96,
  NUMPAD_NUMBERS_END: 105,
  SPECIAL_SYMBOLS_START: 186,
  SPECIAL_SYMBOLS_END: 222,
  NUMPAD_SPECIAL_SYMBOLS_START: 106,
  NUMPAD_SPECIAL_SYMBOLS_END: 111,
}

import { merge, toPairs, update, insert } from 'ramda';

function getLocationOfChildInParent<T extends { id: string }, U extends Record<string, T[]>, K extends keyof U>(
  possibleChild: T,
  parent: U,
  arrayKey: K,
): number {
  return parent[arrayKey].findIndex((element) => element.id === possibleChild.id);
}

function clone<T extends Record<string, any>>(record: T): T {
  const newRecord: any = {} as T;
  toPairs(record).forEach(([key, value]: [string, any]) => {
    newRecord[key] = value;
  });
  return newRecord;
}

export function getNewEntitiesIfNeeded<T extends { id: string }, U extends Record<string, any>>(
  child: T,
  parentRecord: U,
  keys: string[],
): U {
  const result = {};

  keys.forEach((key: string) => {
    toPairs(parentRecord)
      .forEach(([id, parent]: [string, U]) => {
        if (isNewObjectToAdd(child, parent, key) || isKeyArrayAndHasChild(child, parent, key)) {
          result[id] = cloneParentWithUpdateChildForArray(child, parentRecord[id], key);
        } else if (isKeySingleObjectAndNeedUpdate(child, parent, key)) {
          result[id] = clone(parentRecord[id])
          result[id][key] = child;
        }
      });
  });

  return merge(parentRecord, result);
}

function isKeyArrayAndHasChild<T extends { id: string }>(child: T, parent: Record<string, any>, key: string): boolean {
  return !!parent[key] && Array.isArray(parent[key]) && getLocationOfChildInParent(child, parent, key) !== -1;
}

function isNewObjectToAdd<T extends { id: string, diagrams?: Array<{ id: string }> }>(child: T, parent: Record<string, any>, key: string): boolean {
  return !!parent[key] && Array.isArray(parent[key]) && child.diagrams && child.diagrams.some(d => d.id === parent.id) &&
    !parent[key].some(obj => obj.id === child.id);
}

function isKeySingleObjectAndNeedUpdate<T extends { id: string }>(child: T, parent: Record<string, any>, key: string): boolean {
  return !!parent[key] && !Array.isArray(parent[key]) && parent[key].id === child.id;
}

function cloneParentWithUpdateChildForArray<T extends { id: string }>(child: T, parent: Record<string, any>, key: string): Record<string, any> {
  const index = getLocationOfChildInParent(child, parent, key);
  const newParent = clone(parent);
  if (index === -1) {
    newParent[key] = insert(parent[key].length, child, parent[key]);
  } else {
    newParent[key] = update(index, merge(parent[key][index], child), parent[key]);
  }
  return newParent;
}

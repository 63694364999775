<div>
  <h2 class="text-center">{{ 'resources.forgotPassword' | translate }}</h2>
  <div class="form-group">
    <input #emailInput="ngModel" [(ngModel)]="email" name="username" required email type="text" class="form-control"
      placeholder="Email" [pattern]="EMAIL_PATTERN">
  </div>

  <div class="button-container">
    <button class="btn form-control btn-info mr-2" type="button" (click)="onSendForgotPassword()"
      [disabled]="!emailInput.valid || inProgress" [nbSpinner]="inProgress" nbSpinnerStatus="basic"
      nbSpinnerSize="large">
      {{ 'resources.forgotPassword' | translate }}
    </button>
  </div>
</div>
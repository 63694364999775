import { EnvName, IdentityManagement } from './config.model';
import { IConfigMap } from './configMap';

export const tempDevConfig: IConfigMap = {
    environment: EnvName.TEMPDEV,
    name: EnvName.TEMPDEV,
    proxyUri: '',
    rootUri: 'https://temp-dev.api.decisionsfirst.com/',
    authBaseUrl: 'https://temp-dev.api.decisionsfirst.com/ims/',
    identityManagement: IdentityManagement.INAPP
};
<div>
  <form [formGroup]="form">
    <h2 class="text-center">{{ 'resources.login' | translate }}</h2>
    <nb-alert status="warning" *ngIf="!form.valid && submitAttempted">
      {{ 'resources.usernameAndPasswordAreRequired' | translate }}
    </nb-alert>
    <ng-container *ngIf="!authenticationState.authenticationInProgress && submitAttempted && form.valid">
      <nb-alert status="danger" *ngIf="authenticationState.emailNotValidError">
        {{ 'resources.emailIsNotValid' | translate }}
      </nb-alert>
      <nb-alert status="danger" *ngIf="authenticationState.authenticationError">
        {{ 'resources.wrongPassword' | translate }}
      </nb-alert>
      <nb-alert status="danger" *ngIf="authenticationState.authorizationError">
        {{ 'resources.onlyAdministratorsHaveAccess' | translate }}
      </nb-alert>
      <nb-alert status="danger" *ngIf="authenticationState.userDisabled">
        {{ 'resources.userDisabled' | translate }}<br>{{ 'resources.assistanceMessage' | translate }}
        <!-- User Disabled, Please contact Admin. -->
      </nb-alert>
      <nb-alert status="danger" *ngIf="authenticationState.customerExpired">
        {{ 'resources.customerExpired' | translate }}<br>{{ 'resources.assistanceMessage' | translate }}
      </nb-alert>
    </ng-container>
    <div class="form-group">
      <input type="text" class="form-control" placeholder="Username" formControlName="username">
    </div>
    <div class="form-group password-container">
      <input [type]="isPasswordVisible ? 'text' : 'password'" class="form-control" placeholder="Password"
        formControlName="password">
      <img [src]="isPasswordVisible ? 'assets/images/hidePasswordIcon.png' : 'assets/images/showPasswordIcon.png'"
        class="password-icon" (mousedown)="isPasswordVisible = true" (mouseup)="isPasswordVisible = false"
        (mouseleave)="isPasswordVisible = false">
    </div>
    <div class="primary-color w-100 d-inline-flex justify-content-between mb-3 pr-1 pl-1"
      *ngIf="showRememberOption || showForgotPasswordOption">
      <div class="d-inline-flex" *ngIf="showRememberOption">
        <input type="checkbox" class="remember-checkbox" (change)="rememberLoginInfo.emit($event.target.checked)">
        <span>Remember</span>
      </div>
      <div class="link" (click)="forgotPassword.emit()" *ngIf="showForgotPasswordOption">{{ 'resources.forgotPassword' |
        translate }}?</div>
    </div>
    <div class="form-group">
      <button class="btn form-control btn-info mr-2 login-btn" (click)="onLoginClicked()"
        [nbSpinner]="!loginButtonAvailable()" nbSpinnerStatus="basic" nbSpinnerSize="large">
        {{ 'resources.login' | translate }}
      </button>
    </div>
  </form>
</div>
